import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import FriendInviteModal from './FriendInviteModal'
import { Grid, Row, Col, Button } from 'react-bootstrap'
class ProfileWelcomePanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: window.ezyraise.currentUser,
      token: "",
      successImg: "",
      errorImg: "",
      invitingFriendsCustomText: "",
    }
  }
  goToUrl = (path) => {
    window.location.href = path
  }
  showShareProfile = () => {
    window.jQuery("#profile-post").modal('show');
  }
  showJoinOrCreateTeam = () => {
    window.jQuery("#join-team-passive").modal('show');
  }
  showInviteFriend = () => {
    this.showModal();
  }
  inviteFriendModalRef = ({handleShow}) => {
    this.showModal = handleShow;
  }
  componentDidMount = () => {
    this.setState(
      {
        token: this.props.data.token,
        successImg: this.props.data.success_img,
        errorImg: this.props.data.error_img,
        invitingFriendsCustomText: this.props.data.inviting_friends_custom_text
      }
    );
  }
  team = () => {
    var text = 'Join or Create a Team';
    var link = this.showJoinOrCreateTeam;
    if (this.state.user.team_id) {
      text = 'View / Edit Team Profile';
      link = () => this.goToUrl(`/teams/${this.state.user.team_slug}`);
    }
    return (
      <Col xs={12} md={6}>
        <Button onClick={ link }>
          <i className="fa fa-users pull-left" />
          { text }
          <i className="fa fa-chevron-right pull-right"></i>
        </Button>
      </Col>
    )
  }
  showInviteFriends = () => {
    if (this.state.user.hide_inviting_friend) {
      return false;
    }
    return !this.state.user.team_building_enabled || (this.state.user.team_building_enabled && this.state.user.team_leader)
  }
  showJoinTeam = () => {
    if (this.state.user.hide_join_team) {
      return false;
    }
    return true;
  }
  showCalendar = () => {
    if (this.state.user.show_calendar_link_in_welcome_screen) {
      return true;
    }
    return false;
  }
  showQRWallpaper = () => {
    if (this.state.user.qr_wallpaper_url_enabled && this.state.user.qr_wallpaper_url) {
      return true;
    }
    return false;
  }

  render = () => {
    return (
      <div className="profile-welcome-panel">
        <Grid fluid={true}>
          <Row className="show-grid">
            <Col md={12}>
              <h2>Hey {this.state.user.first_name}!</h2>
              <span className="sub-desc">
                Here are some handy links to help with your fundraising
              </span>
            </Col>
          </Row>
          <Row className="links">
            {
              this.showInviteFriends() &&
              <Col xs={12} md={6}>
                <Button onClick={this.showInviteFriend}>
                  <i className="fa fa-envelope pull-left" />
                  Invite Friends
                  <i className="fa fa-chevron-right pull-right"></i>
                </Button>
              </Col>
            }
            {
              this.showJoinTeam() &&
              this.team()
            }
            <Col xs={12} md={6}>
              <Button onClick={this.showShareProfile}>
                <i className="fa fa-share pull-left" />
                Share My Profile
                <i className="fa fa-chevron-right pull-right"></i>
              </Button>
            </Col>
            <Col xs={12} md={6}>
              <Button onClick={ () => this.goToUrl('/spread_the_word')}>
                <i className="fa fa-bullhorn pull-left" />
                Spread The Word
                <i className="fa fa-chevron-right pull-right"></i>
              </Button>
            </Col>
            {
              this.showCalendar() &&
              <Col xs={12} md={6}>
                <Button onClick={ () => this.goToUrl('/calendar')}>
                  <i className="fa fa-calendar pull-left" />
                  Calendar
                  <i className="fa fa-chevron-right pull-right"></i>
                </Button>
              </Col>
            }
            {
              this.showQRWallpaper() &&
              <Col xs={12} md={6}>
                <Button onClick={ () => window.open(this.state.user.qr_wallpaper_url, '_blank') }>
                  <i className="fa fa-qrcode pull-left" />
                  Download QR Code
                  <i className="fa fa-chevron-right pull-right"></i>
                </Button>
              </Col>
            }
          </Row>
        </Grid>
        <FriendInviteModal ref={this.inviteFriendModalRef}
          invitingFriendsCustomText={this.state.invitingFriendsCustomText}
          token={this.state.token}
          errorImg={this.state.errorImg}
          successImg={this.state.successImg}/>
      </div>
    )
  }
}

export default ProfileWelcomePanel
