// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import { css } from 'glamor'
import { DropdownButton, MenuItem, Row, Col, FormGroup, ControlLabel, FormControl, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { ReactSVG } from 'react-svg'

const dropDownButtonDefaultText = "Specify an unit"

class ChallengePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      "challenges": [],
      "snippets": [],
      "currentChallenge": {},
      "defaultGoals": [],
      "currentGoal": {},
      "current_quantity_per_day": "",
      "current_quantity_unit": this.props.quantity_unit || "",
      "dropDownTitle": this.props.quantity_unit || dropDownButtonDefaultText,
      showCurrentChallengeFieldError: false,
      showCurrentGoalFieldError: false,
      showUnitError: false,
    }
  }

  findResourceById = (id, resources) => {
    let num = id

    if (num) {
      num = num.toString()
    } else {
      return {}
    }

    const targetResource = resources.find((resource) => {
      return resource.id.toString() === num
    })

    if (targetResource) {
      return targetResource
    }

    return {}
  }

  fetchChallengeConfig = (secure = true) => {
    const domain = secure ? (this.props.domain || '').replace('http://', 'https://') : (this.props.domain || '')
    const url = domain + '/api/v3/challenges.json'
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        this.setState({
          challenges: json.data,
          defaultGoals: json.default_goals
        })

        this.setCurrentChallenge(this.findResourceById(this.props.challenge_id, this.state.challenges))

        if (this.state.currentChallenge.id && this.props.goal_id) {
          this.setCurrentGoal(this.findResourceById(this.props.goal_id, this.state.currentChallenge.attributes.goals))
        }

        if (this.state.currentGoal.is_other) {
          this.setState({current_quantity_per_day: this.props.quantity_per_day})
        }
      }).catch((error) => {
        if (secure) {
          this.fetchChallengeConfig(false)
        }
      });
  }

  fetchLabelConfig = (secure = true) => {
    const domain = secure ? (this.props.domain || '').replace('http://', 'https://') : (this.props.domain || '')
    const url = domain + '/api/v3/snippets.json'
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        this.setState({
          snippets: json.data
        })
      }).catch((error) => {
        if (secure) {
          this.fetchLabelConfig(false)
        }
      });
  }

  tooltip = () => {
    $('.other-challenge-tooltip').tooltip({
      container: 'body'
    })
  }

  componentDidUpdate = () => {
    this.tooltip();
  }

  componentDidMount = () => {
    this.fetchChallengeConfig()
    this.fetchLabelConfig()
    this.tooltip();
    const form = document.querySelector('.edit_user, .edit_campaign_instance_user')
    const that = this
    if (form) {
      form.onsubmit = function(event) {
        if (!that.state.currentChallenge.id) {
          event.preventDefault()
          that.setState({showCurrentChallengeFieldError: true})
        } else {
          that.setState({showCurrentChallengeFieldError: false})
        }
  
        if (that.state.currentChallenge.id && that.goals().length > 0 && !that.state.current_quantity_per_day) {
          event.preventDefault()
          that.setState({showCurrentGoalFieldError: true})
        } else {
          that.setState({showCurrentGoalFieldError: false})
        }

        if (that.goal_is_custom_unit() && (that.state.current_quantity_unit === "" || that.state.current_quantity_unit === dropDownButtonDefaultText)) {
          event.preventDefault()
          that.setState({showUnitError: true})
        } else {
          that.setState({showUnitError: false})
        }

        const submitButton = document.querySelector('.btn-primary')
        if (submitButton) {
          setTimeout(() => {
            submitButton.removeAttribute("disabled")	
          }, 500)
        }
      }
    }
  }

  goal_is_custom_unit = () => {
    return this.goals().length === 1 && this.goals()[0].is_custom_unit
  }

  styles = () => (
    {
      "sectionHeader": {
          margin: "34px 0 8px",
          fontFamily: "Helvetica",
          fontSize: "22px",
          fontWeight: "bold",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "normal"
      },
      "sectionDesc": {
          margin: "8px 0 60px",
          fontFamily: "Helvetica",
          fontSize: "15px",
          fontWeight: "normal"
      },
      "sectionLabel": {
        fontFamily: "Helvetica",
        fontSize: "16px",
        fontWeight: "bold",
        fontStretch: "normal",
        color: "#000",
        display: "block"
      },
      "challengeButton": {
        marginBottom: "15px"
      }
    }
  )

  renderSectionLabel = (identifier) => {
    const snippet = this.state.snippets.find(element => (
      element.attributes.identifier == identifier
    ))
    
    let text = identifier

    if (snippet) {
      text = snippet.attributes.content
    }

    if (text.length > 0) {
      return (
        <span>
          { text }
        </span>
      )
    }
  }

  setCurrentChallenge = (challenge) => {
    if (challenge.id !== this.state.currentChallenge.id) {
      this.setState({currentGoal: {}})
    }
    this.setState({
      currentChallenge: challenge,
      current_quantity_per_day: this.props.quantity_per_day,
      current_quantity_unit: this.state.dropDownTitle || "",
      showCurrentChallengeFieldError: false
    })
  }

  setCurrentGoal = (goal, event) => {
    this.setState({
      currentGoal: goal,
      current_quantity_per_day: goal.quantity_per_day,
      current_quantity_unit: goal.is_custom_unit === true ? this.state.dropDownTitle : goal.quantity_unit,
      showCurrentGoalFieldError: false
    })

    document.querySelectorAll(".other-goal-field ").forEach((element) => {
      if (event && event.target !== element) {
        element.value = ''
      }
    })

    if (event && event.target) {
      this.setCurrentQuantityPerDay(event.target.value || goal.quantity_per_day)
    }
  }

  setCurrentQuantityPerDay = (value) => {
    this.setState({
      current_quantity_per_day: value,
    })
  }

  showChallengeErrorMessage = () => {
    if (this.state.showCurrentChallengeFieldError) {
      return (
        <span className="help-block filled">
          <div style={{color: '#a94442'}} className="parsley-custom-error-message">Please specify a challenge</div>
        </span>
      )
    }

    return ''
  }

  showGoalErrorMessage = () => {
    if (this.state.showCurrentGoalFieldError) {
      return (
        <span className="help-block filled">
          <div style={{color: '#a94442'}} className="parsley-custom-error-message">Please specify a goal</div>
        </span>
      )
    }

    return ''
  }

  showUnitErrorMessage = () => {
    if (this.state.showUnitError) {
      return (
        <span className="help-block filled">
          <div style={{color: '#a94442'}} className="parsley-custom-error-message">Please specify an unit</div>
        </span>
      )
    }

    return ''
  }
  
  renderChallengeOptions = () => {
    const sortedChallenges = this.state.challenges.sort(function(a, b) {
      return a.attributes.position - b.attributes.position;
    });

    const sortedChallengeCols = sortedChallenges.map((challenge) => {
      const styles = css({
        ' rect': {
          fill: 'aqua',
          height: 27,
          stroke: 'darkmagenta',
          width: 22,
        },
        ' svg': {
          height: 27,
          width: 22,
        },
      })

      return (
        <Col style={this.styles().challengeButton} key={challenge.id} xs={12/challenge.attributes.columns_count_xs} sm={12/challenge.attributes.columns_count_sm} onClick={() => {this.setCurrentChallenge(challenge)}}>
          {
            challenge.attributes.icon_url
              ? <label style={{height: 92, paddingTop: 18, paddingLeft: 4, paddingRight: 4, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} className={"btn btn-lg btn-toggle w-100" + (challenge.id === this.state.currentChallenge.id ? " down active" : "")}>
                  <ReactSVG {...styles} src={challenge.attributes.icon_url} />
                  { challenge.attributes.name }
                </label>
              : <label style={{height: 92, paddingTop: 18, paddingLeft: 4, paddingRight: 4, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} className={("btn btn-lg btn-toggle w-100") + (challenge.id === this.state.currentChallenge.id ? " down active" : "")}>
                  { challenge.attributes.name }
                </label>
          }
        </Col>
      )
    })

    return (
      <div>
        <span style={{...this.styles().sectionLabel, marginBottom: '0.25em'}}>{ this.renderSectionLabel("select_challenge_label") } <abbr title="required">*</abbr></span>
        <Row className="gutters-sm btn-toggle-group">
          { sortedChallengeCols }
        </Row>
        { this.showChallengeErrorMessage() }
      </div>
    )
  }

  renderOtherGoalField = (goal) => {
    var defaultValue;
    if (goal.id === this.state.currentGoal.id) {
      defaultValue = this.state.current_quantity_per_day;
    } else {
      defaultValue =this.state.current_quantity_per_day || goal.default_quantity;
      this.setCurrentGoal(goal, { target: { value: this.state.current_quantity_per_day || goal.default_quantity }})
    }

    return (
      <FormControl
        className="other-goal-field"
        type="text"
        pattern="\d*"
        maxLength="4"
        defaultValue={defaultValue}
        onChange={(event) => { this.setCurrentGoal(goal, event) }}
      />
    )
  }

  changeValue = (text) => {
    this.setState({
      dropDownTitle: text,
      current_quantity_unit: text,
      showUnitError: false
    })
  }

  renderOtherGoalFields = () => {
    const sortedOtherGoals = this.goals().filter(goal => goal.is_other).sort(function(a, b) {
      return a.position - b.position;
    });

    if (sortedOtherGoals.length > 0) {
      return (
        <Row>
          <Col style={this.styles().challengeButton} xs={12} sm={6}>
            <FormGroup>
              <ControlLabel style={{fontFamily: 'Helvetica', fontWeight: 'bold', fontSize: '1.6rem'}}>
                { this.renderSectionLabel("other_goal_label") }
              </ControlLabel>
              {
                sortedOtherGoals.map(goal => {
                  return (
                    <InputGroup key={goal.id} style={{marginBottom: "10px"}}>
                      { this.renderOtherGoalField(goal) }
                      {
                        goal.is_custom_unit === true
                        ? <DropdownButton
                            componentClass={InputGroup.Button}
                            style={{width: "140px", height: "100%"}}
                            title={ this.state.dropDownTitle }
                            id="custom-unit-select"
                          >
                            {
                              goal.custom_units.map((custom_unit, index) => {
                                return <MenuItem key={ index } onClick={(e) => this.changeValue(e.target.textContent)}>{ custom_unit }</MenuItem>
                              })
                            }
                          </DropdownButton>
                        : <InputGroup.Addon>{goal.quantity_unit}s</InputGroup.Addon>
                      }
                    </InputGroup>
                  )
                })
              }
            </FormGroup>
          </Col>
          <Col style={{marginTop: "-15px", marginBottom: "10px"}} xs={12} sm={12}>
            { this.showChallengeTotalConsumption() }
          </Col>
        </Row>
      )
    }
  }

  showChallengeTotalConsumption = () => {
    if (this.state.current_quantity_per_day && this.props.workout_duration_in_days) {
      const workout_duration_in_days = this.props.workout_duration_in_days
      if (this.state.current_quantity_unit === "minute") {
        return (<span>This equals {(parseFloat(this.state.current_quantity_per_day) * workout_duration_in_days / 60.0).toFixed(1)} hours for the duration of the challenge</span>)
      } else if (this.goal_is_custom_unit()) {
        return (<span>This equals {(parseFloat(this.state.current_quantity_per_day) * workout_duration_in_days).toFixed(1)} {this.state.current_quantity_unit} for the duration of the challenge</span>)
      } else {
        return (<span>This equals {(parseFloat(this.state.current_quantity_per_day) * workout_duration_in_days).toFixed(1)} {this.state.current_quantity_unit}s for the duration of the challenge</span>)
      }
    }
  }

  goals = () => {
    return this.state.currentChallenge.attributes
                  ? this.state.currentChallenge.attributes.goals
                  : this.state.defaultGoals ? this.state.defaultGoals : []
  }

  renderGoalOptions = () => {
    return (
      <div>
        { this.renderGoals() }
        { this.renderOtherGoalFields() }
        { this.showGoalErrorMessage() }
        { this.showUnitErrorMessage() }
      </div>
    )
  }

  selectGoalLabel = () => {
    if (this.renderSectionLabel("select_goal_label")) {
      return <span style={{...this.styles().sectionLabel, marginTop: 30}}>{ this.renderSectionLabel("select_goal_label") } <abbr title="required">*</abbr></span>
    }
  }

  selectGoalDesc = () => {
    if (this.renderSectionLabel("select_goal_desc")) {
      return <span style={{...this.styles().sectionDesc, marginTop: 20}}>{ this.renderSectionLabel("select_goal_desc") }</span>
    }
  }

  renderGoals = () => {
    const sortedGoals = this.goals().filter(goal => !goal.is_other).sort(function(a, b) {
      return a.position - b.position;
    });

    const sortedGoalCols = sortedGoals.map((goal) => {
      const styles = css({
        ' rect': {
          fill: 'aqua',
          height: 27,
          stroke: 'darkmagenta',
          width: 22,
        },
        ' svg': {
          height: 27,
          width: 22,
        },
      })

      return (
        <Col style={this.styles().challengeButton} key={goal.id} xs={12/goal.columns_count_xs} sm={12/goal.columns_count_sm} onClick={(event) => {this.setCurrentGoal(goal, event)}}>
          {
            <label style={{height: 92, lineHeight: "25px", paddingTop: 18}} className={("btn btn-lg btn-toggle w-100") + (goal.id === this.state.currentGoal.id ? " down active" : "")}>
              <div className={("goal-text1")}>{ goal.text1 }</div>
              <div className={("goal-text2")}>{ goal.text2 }</div>
            </label>
          }
        </Col>
      )
    })

    return (
      <>
        { this.selectGoalLabel() }
        { this.selectGoalDesc() }
        <Row style={{marginTop: 15}} className="gutters-sm btn-toggle-group">
          { sortedGoalCols }
        </Row>
      </>
    )
  }

  otherChallengeTooltip = () => {
    const tooltipSnippet = this.state.snippets.find(element => (
      element.attributes.identifier == "other_challenge_question"
    ))
    if (tooltipSnippet) return tooltipSnippet.attributes.content
    return "other_challenge_question"
  }

  renderOtherChallegeField = () => {
    if (this.state.currentChallenge.attributes && this.state.currentChallenge.attributes.is_other) {
      return (
        <FormGroup>
          <label className="control-label string optional help-tooltip required">
            {this.renderSectionLabel("other_challenge_label")} <abbr title="required">*</abbr>
            <i className="other-challenge-tooltip help-icon fa fa-question-circle" title={ this.otherChallengeTooltip() }></i>
          </label>
          <FormControl
            id="formControlsText"
            className="other_challenge_field"
            name={this.props.parameter_scope + "[self_elected_activity]"}
            defaultValue={this.props.self_elected_activity}
            type="text"
            data-parsley-error-message="Please enter your activity"
            required="required"
            aria-required="true"
            maxLength="30"
          />
        </FormGroup>
      )
    }
  }

  render = () => {
    const iconStyle = {
      padding: 4,
      backgroundColor: '#dddddd',
      borderRadius: 4
    }
    
    return (
      <div>
        <hr />
        <h3 style={this.styles().sectionHeader}>{this.renderSectionLabel("select_activity_header")}</h3>
        <h5 style={this.styles().sectionDesc}>{this.renderSectionLabel("select_activity_desc")}</h5>
        { this.renderChallengeOptions() }
        { this.renderOtherChallegeField() }
        { this.renderGoalOptions() }
        {
          this.state.currentChallenge.id &&
          <input name={this.props.parameter_scope + "[challenge_id]"} value={this.state.currentChallenge.id} type="hidden" />
        }
        {
          this.state.currentGoal.id &&
          <input name={this.props.parameter_scope + "[goal_id]"} value={this.state.currentGoal.id} type="hidden" />
        }
        {
          this.state.current_quantity_per_day &&
          <input name={this.props.parameter_scope + "[quantity_per_day]"} value={this.state.current_quantity_per_day} type="hidden" />
        }
        {
          this.state.current_quantity_unit &&
          <input name={this.props.parameter_scope + "[quantity_unit]"} value={this.state.current_quantity_unit} type="hidden" />
        }
      </div>
    )
  }
}

export default ChallengePicker
